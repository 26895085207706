import { useFormik } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from '../../components/ImageUploader';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { eventValidationSchema } from './columns/EventColumn';

interface AgendaSection {
  startTime: string;
  endTime: string;
  title: string;
  description: string;
}
interface InitialValuesType {
  id: any;
  title: string;
  imageUrl: string;
  date: string;
  startTime: string;
  endTime: string;
  aboutEvent?: string;
  eventAgenda: AgendaSection[];
  eventImagesUrl?: string[];
  status?: string;
  externalImageUrl?: string;
  location: string;
}

const EventForm = ({
  initialValues,
  handleCloseForm,
  isPastEvent
}: {
  initialValues: InitialValuesType;
  handleCloseForm: () => void;
  isPastEvent: boolean;
}) => {
  const { createEvents, updateEvents } = useSelector(
    (state: RootState) => state
  );

  const [agenda, setAgenda] = useState<AgendaSection[]>(
    initialValues.eventAgenda || []
  );

  const dispatch = useDispatch();

  const addSection = () => {
    setAgenda((prev) => [
      ...prev,
      { startTime: '', endTime: '', title: '', description: '' }
    ]);
  };

  const removeSection = (index: number) => {
    setAgenda((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAgendaChange = (
    index: number,
    field: keyof AgendaSection,
    value: string
  ) => {
    setAgenda((prev) =>
      prev.map((section, i) =>
        i === index ? { ...section, [field]: value } : section
      )
    );
  };

  const [previewUrls, setPreviewUrls] = useState<string[]>([
    initialValues.imageUrl
  ]);

  const [highlightPreviewUrls, setHighlightPreviewUrls] = useState<string[]>(
    initialValues.eventImagesUrl ? initialValues.eventImagesUrl : []
  );
  const handlePreviewUrlsChange = (urls: string[]) => {
    setPreviewUrls(urls);
  };
  const handleHighlightPreviewUrlsChange = (urls: string[]) => {
    setHighlightPreviewUrls(urls);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: eventValidationSchema,
    onSubmit: (values) => {
      values.eventAgenda = agenda;
      if (values.id) {
        dispatch({
          type: states.updateEvents.constants.request,
          params: { id: values?.id },
          payload: {
            ...values,
            imageUrl: previewUrls[0],
            eventImagesUrl: highlightPreviewUrls,
            status: 'published'
          }
        });
      } else {
        const { id, ...restValues } = values;
        console.log(id);
        dispatch({
          type: states.createEvents.constants.request,
          payload: {
            ...restValues,
            imageUrl: previewUrls[0],
            eventImagesUrl: highlightPreviewUrls,
            status: 'published'
          }
        });
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div key="title" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="title"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Event Name <span className="text-red-500">*</span>
          </label>
          <input
            id="title"
            name="title"
            type="text"
            placeholder="Enter event name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            className={`border-gray-300 mt-1 block w-1/2 rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand ${
              formik.errors.title && formik.touched.title
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.title && formik.touched.title && (
            <p className="text-xs text-red-500">{formik.errors.title}</p>
          )}
        </div>
        <div key="location" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="location"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Event Location <span className="text-red-500">*</span>
          </label>
          <input
            id="location"
            name="location"
            type="text"
            placeholder="Enter event location"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
            className={`border-gray-300 mt-1 block w-1/2 rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand ${
              formik.errors.location && formik.touched.location
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.location && formik.touched.location && (
            <p className="text-xs text-red-500">{formik.errors.location}</p>
          )}
        </div>
        <FileUploader
          key="imageUrl"
          onPreviewUrlsChange={handlePreviewUrlsChange}
          title={'Add Image'}
          maxImages={1}
          initialValue={initialValues.imageUrl}
        />
        <div key="date" className="mb-5 mt-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="date"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Event Information <span className="text-red-500">*</span>
          </label>
          <div className="container flex space-x-2">
            <DatePicker
              selected={
                formik.values.date ? new Date(formik.values.date) : null
              }
              onChange={(date) =>
                formik.setFieldValue('date', date?.toISOString().split('T')[0])
              }
              name="date"
              dateFormat="yyyy-MM-dd"
              autoComplete="off"
              className="border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
              placeholderText="Select date"
              required
            />
            <DatePicker
              selected={
                formik.values.startTime
                  ? new Date(`1970-01-01T${formik.values.startTime}`)
                  : null
              }
              onChange={(time) =>
                formik.setFieldValue(
                  'startTime',
                  time?.toLocaleTimeString('en-US', { hour12: false })
                )
              }
              name="startTime"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="hh:mm aa"
              autoComplete="off"
              className="border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
              placeholderText="Select start time"
              required
            />
            <DatePicker
              selected={
                formik.values.endTime
                  ? new Date(`1970-01-01T${formik.values.endTime}`)
                  : null
              }
              onChange={(time) =>
                formik.setFieldValue(
                  'endTime',
                  time?.toLocaleTimeString('en-US', { hour12: false })
                )
              }
              name="endTime"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="hh:mm aa"
              autoComplete="off"
              className="border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
              placeholderText="Select end time"
              required
            />
          </div>
        </div>
        <div key="aboutEvent" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="aboutEvent"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            About Event <span className="text-red-500">*</span>
          </label>
          <textarea
            id="aboutEvent"
            name="aboutEvent"
            placeholder="Describe what the event is about"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.aboutEvent}
            className="border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
          />
        </div>
        <div className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="eventAgenda"
            className="text-gray-700 block text-sm font-medium"
          >
            Event Agenda <span className="text-red-500">*</span>
          </label>
          {agenda.map((section, index) => (
            <div key={index} className="mb-4 rounded-lg border p-4">
              <div>
                <label className="text-gray-700 block text-sm font-medium">
                  Start Time
                </label>
                <DatePicker
                  selected={
                    section.startTime
                      ? new Date(`1970-01-01T${section.startTime}`)
                      : null
                  }
                  onChange={(time) =>
                    handleAgendaChange(
                      index,
                      'startTime',
                      time?.toLocaleTimeString('en-US', { hour12: false }) || ''
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="hh:mm aa"
                  autoComplete="off"
                  className="border-gray-300 mt-1 block w-1/2 rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="text-gray-700 block text-sm font-medium">
                  End Time
                </label>
                <DatePicker
                  selected={
                    section.endTime
                      ? new Date(`1970-01-01T${section.endTime}`)
                      : null
                  }
                  onChange={(time) =>
                    handleAgendaChange(
                      index,
                      'endTime',
                      time?.toLocaleTimeString('en-US', { hour12: false }) || ''
                    )
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="hh:mm aa"
                  autoComplete="off"
                  className="border-gray-300 mt-1 block w-1/2 rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="text-gray-700 block text-sm font-medium">
                  Title
                </label>
                <input
                  type="text"
                  value={section.title}
                  onChange={(e) =>
                    handleAgendaChange(index, 'title', e.target.value)
                  }
                  className="border-gray-300 mt-1 block w-1/2 rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="text-gray-700 block text-sm font-medium">
                  Description
                </label>
                <textarea
                  value={section.description}
                  onChange={(e) =>
                    handleAgendaChange(index, 'description', e.target.value)
                  }
                  className="border-gray-300 mt-1 block w-1/2 rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
                ></textarea>
              </div>
              <button
                type="button"
                onClick={() => removeSection(index)}
                className="mt-2 text-red-500"
              >
                Remove Agenda
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addSection}
            className="rounded-lg bg-brand px-4 py-2 text-white"
          >
            <span className="font-inter text-left text-base text-sm font-semibold">
              + Add agenda
            </span>
          </button>
        </div>
        {isPastEvent ? (
          <>
            <FileUploader
              initialValue={initialValues.eventImagesUrl}
              onPreviewUrlsChange={handleHighlightPreviewUrlsChange}
              title={'Add event highlights Image'}
            />
            <div
              key="externalImageUrl"
              className="mb-5 mt-5 rounded-[10px] bg-white p-5"
            >
              <label
                htmlFor="externalImageUrl"
                className="text-gray-700 block text-sm font-medium"
              >
                {' '}
                Embed link{' '}
              </label>
              <input
                id="externalImageUrl"
                name="externalImageUrl"
                type="text"
                placeholder="http://www.website.com"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.externalImageUrl}
                className="border-gray-300 mt-1 block w-1/2 rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="mt-6 flex w-full justify-end">
          <div className="my-4 flex w-1/4 gap-3 text-sm font-semibold">
            <button
              onClick={handleCloseForm}
              className="w-full rounded-lg border border-brand-50 bg-none p-2 text-brand transition duration-300 ease-in-out hover:bg-slate-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-full justify-center rounded-lg border border-brand-200 bg-brand p-2 text-white transition duration-300 ease-in-out hover:bg-brand-400"
            >
              {createEvents.loading || updateEvents.loading ? (
                <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-current" />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EventForm;
