import { ActionButtons } from '../../../components/ActionButtons';
import ProfileAvatar from '../../../components/ProfileAvatar';
import * as Yup from 'yup';

export const teamColumns = (actions: any) => [
  {
    name: 'Team Members',
    cell: (row: any) => [
      <ProfileAvatar
        key={row.id}
        firstName={row.firstName}
        lastName={row.lastName}
        profileImage={row.profileImage}
      />
    ]
  },
  {
    name: 'Job Title',
    selector: (row: any) => row?.jobTitle
  },
  {
    name: 'Department',
    selector: (row: any) => row.department?.name
  },
  {
    name: 'Actions',
    type: 'actions',
    cell: (row: any) => [
      row.role !== 'admin' ? (
        <ActionButtons row={row} key={row.id} actions={actions} />
      ) : (
        '---'
      )
    ]
  }
];

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The first name is too short!')
    .required('First name is required'),
  lastName: Yup.string()
    .transform((value) => value.trim())
    .min(2, 'The last name is too short!')
    .required('Last name is required'),
  email: Yup.string()
    .transform((value) => value.trim())
    .email('Invalid email')
    .required('User email is required')
  //   department: Yup.string().required(`User's department is required`)
});
