import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { HiOutlineTrash } from 'react-icons/hi2';
import FileUploader from '../../components/ImageUploader';
import { RiAddLargeFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useEffect, useState } from 'react';
import { states } from '../../redux/states/StateBuilder';
import { useAuth } from '../../providers/AuthProvider';
import Select from 'react-select';

interface NewsletterFormValues {
  id: any;
  title: string;
  mainDescription: string;
  mainImage: string;
  departmentId: any;
  descriptionImage: string;
  descriptionTitle: string;
  sections: { secTitle: string; description: string; image: string }[];
}

const AddNewsletterForm = ({
  initialValues,
  handleCloseForm
}: {
  initialValues: NewsletterFormValues;
  handleCloseForm: () => void;
}) => {
  const listDepartments = useSelector(
    (state: RootState) => state.listDepartments
  );
  const createNewsletter = useSelector(
    (state: RootState) => state.createNewsletter
  );

  const updateNewsletter = useSelector(
    (state: RootState) => state.updateNewsletter
  );

  const { auth } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: states.listDepartments.constants.request
    });
  }, []);

  const departmentOptions =
    ((listDepartments.data as any[]) || []).map(
      (company: { id: string; name: string }) => ({
        value: company.id,
        label: company.name
      })
    ) || [];
  
  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required')
  });

  const [previewUrls, setPreviewUrls] = useState<string[]>(
    initialValues.sections.map((section) => section.image)
  );
  const [mainImage, setMainImage] = useState<string[]>([
    initialValues.mainImage ? initialValues.mainImage : ''
  ]);
  const [descriptionImage, setDescriptionImage] = useState<string[]>([
    initialValues.descriptionImage ? initialValues.descriptionImage : ''
  ]);

  const handlePreviewUrlsChange = (index: number, urls: string[]) => {
    setPreviewUrls((prev) => {
      const updatedUrls = [...prev];
      updatedUrls[index] = urls[0] || '';
      return updatedUrls;
    });
  };

  const handleNewsLetterMainImagesUrl = (urls: string[], imageType?: string) => {
    if(imageType === 'mainImage'){
      setDescriptionImage(urls);
    } else {
      setMainImage(urls);
    }
  };

  const handleSubmit = (values: NewsletterFormValues) => {
    const { id } = values;

    const formattedSections = values.sections.map((section, index) => ({
      title: section.secTitle,
      description: section.description,
      imageUrl: previewUrls[index] || '' //Section imageUrl
    }));

    const payload = {
      title: values.title,
      description: values.mainDescription,
      departmentId: values.departmentId,
      sections: formattedSections,
      imageUrl: mainImage[0],
      descriptionImage: descriptionImage[0],
      descriptionTitle: values.descriptionTitle,
      userId: auth?.userId,
      status: 'published'
    };
    if (id) {
      dispatch({
        type: states.updateNewsletter.constants.request,
        payload,
        params: { id: id }
      });
    } else {
      dispatch({
        type: states.createNewsletter.constants.request,
        payload
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="m-auto max-w-full rounded-lg bg-[#F8F9FA] p-8 shadow-md">
          <div className="space-y-6">
            <div className="flex w-full">
              {/* Title Section */}
              <div className="flex w-4/5 flex-col space-y-2">
                <label
                  htmlFor="title"
                  className="text-sm font-semibold text-[#344054]"
                >
                  Title
                </label>
                <Field
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  className={`text-gray-700 h-[40px] w-4/5 rounded-md border px-3 text-sm font-light placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                    errors.title && touched.title
                      ? 'border-red-500 focus:ring-red-500'
                      : 'border-slate-200 focus:ring-indigo-500'
                  }`}
                />
                <ErrorMessage
                  name="title"
                  component="p"
                  className="text-xs text-red-500"
                />
              </div>

              {/* Department Section */}
              <div className="flex w-4/5 flex-col space-y-2">
                <label
                  htmlFor="department"
                  className="text-sm font-semibold text-[#344054]"
                >
                  Department
                </label>
                <Select
                  id="departmentId"
                  name="departmentId"
                  options={departmentOptions}
                  placeholder="Select the department"
                  value={
                    departmentOptions.find((option) => option.value === values.departmentId) || null
                  }
                  onChange={(selectedOption) =>
                    setFieldValue('departmentId', selectedOption?.value || '')
                  }
                  className="mt-1 block w-full rounded-lg text-sm"
                  classNamePrefix="react-select"
                />
                <ErrorMessage
                  name="departmentId"
                  component="p"
                  className="text-xs text-red-500"
                />

                <ErrorMessage
                  name="department"
                  component="p"
                  className="text-xs text-red-500"
                />
              </div>
            </div>
            <div>
              <FileUploader
                initialValue={initialValues.mainImage}
                title="Add event main image"
                onPreviewUrlsChange={(urls) =>
                  handleNewsLetterMainImagesUrl(urls, 'mainImage')
                }
              />
            </div>
            <div className="flex w-full">
              {/* Title Section */}
              <div className="flex w-4/5 flex-col space-y-2">
                <label
                  htmlFor="title"
                  className="text-sm font-semibold text-[#344054]"
                >
                  Description Title
                </label>
                <Field
                  id="descriptionTitle"
                  name="descriptionTitle"
                  type="text"
                  placeholder="Enter Description Title"
                  className={`text-gray-700 h-[40px] w-4/5 rounded-md border px-3 text-sm font-light placeholder:text-neutral-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                    errors.title && touched.title
                      ? 'border-red-500 focus:ring-red-500'
                      : 'border-slate-200 focus:ring-indigo-500'
                  }`}
                />
                <ErrorMessage
                  name="descriptionTitle"
                  component="p"
                  className="text-xs text-red-500"
                />
              </div>
            </div>
            <div className="mt-3">
              <label
                htmlFor="description"
                className="text-sm font-semibold text-[#344054]"
              >
                Description
              </label>
              <Field
                as="textarea"
                id="mainDescription"
                name="mainDescription"
                placeholder="Add summary of the event here"
                className={`placeholder:text-gray-700 my-4 h-[100px] w-full rounded-lg border border-slate-200 p-3 text-sm font-light text-[#344054] focus:shadow-lg focus:outline-none focus:ring-1`}
              />
              <ErrorMessage
                name="mainDescription"
                component="p"
                className="text-xs text-red-500"
              />
            </div>
            <div>
              <FileUploader
                initialValue={initialValues.mainImage}
                title="Add description image"
                onPreviewUrlsChange={(urls) =>
                  handleNewsLetterMainImagesUrl(urls)
                }
              />
            </div>
            {/* Sections */}
            <FieldArray name="sections">
              {({ push, remove }) => (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      push({ secTitle: '', description: '', imageUrl: '' })
                    }
                    className="rounded-lg border border-none bg-none py-2 pr-2 font-semibold text-brand-400 transition duration-300 hover:bg-slate-100"
                  >
                    <div className="flex items-center justify-center">
                      <RiAddLargeFill className="mr-2" />
                      <span>Add Newsletter section</span>
                    </div>
                  </button>
                  <div>
                    {values.sections.map((section, index) => (
                      <div
                        key={index}
                        className="mb-6 grid grid-rows-1 gap-1 rounded-lg border border-slate-200 p-4"
                      >
                        <div className="flex justify-between">
                          <label
                            htmlFor={`sections.${index}.description`}
                            className="text-sm font-semibold text-[#344054]"
                          >
                            Section {index + 1}
                          </label>
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="text-brand-300"
                          >
                            <HiOutlineTrash size={20} />
                          </button>
                        </div>
                        <div className="mt-3 flex w-4/5 flex-col space-y-2">
                          <label
                            htmlFor="title"
                            className="text-sm font-semibold text-[#344054]"
                          >
                            Title
                          </label>
                          <Field
                            id={`sections.${index}.secTitle`}
                            name={`sections.${index}.secTitle`}
                            type="text"
                            placeholder="Enter section title"
                            className={`text-gray-700 h-[40px] w-4/5 rounded-md border border-slate-200 px-3 text-sm font-light text-[#344054] placeholder:text-sm focus:shadow-lg focus:outline-none focus:ring-1`}
                          />
                          <ErrorMessage
                            name={`sections.${index}.secTitle`}
                            component="p"
                            className="text-xs text-red-500"
                          />
                        </div>
                        <div className="mt-3">
                          <label
                            htmlFor="description"
                            className="text-sm font-semibold text-[#344054]"
                          >
                            Description
                          </label>
                          <Field
                            as="textarea"
                            id={`sections.${index}.description`}
                            name={`sections.${index}.description`}
                            placeholder="Add text here"
                            className={`placeholder:text-gray-700 my-4 h-[100px] w-full rounded-lg border border-slate-200 p-3 text-sm font-light text-[#344054] focus:shadow-lg focus:outline-none focus:ring-1`}
                          />
                          <ErrorMessage
                            name={`sections.${index}.description`}
                            component="p"
                            className="text-xs text-red-500"
                          />
                        </div>
                        <FileUploader
                          initialValue={section.image}
                          title="Add section image"
                          onPreviewUrlsChange={(urls) =>
                            handlePreviewUrlsChange(index, urls)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FieldArray>

            {/* Submit Button */}
            <div className="flex justify-end space-x-4">
              <button
                type="reset"
                className="rounded-lg border border-brand-50 bg-none px-4 py-2 text-brand transition duration-300 hover:bg-slate-100"
                onClick={handleCloseForm}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-lg bg-brand px-4 py-2 text-white transition duration-300 hover:bg-brand-400"
              >
                {createNewsletter.loading || updateNewsletter.loading ? (
                  <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-current" />
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewsletterForm;
