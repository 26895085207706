import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import Select from 'react-select';
import { companyValidationSchema } from './columns/CompanyColumn';
import { states } from '../../redux/states/StateBuilder';
import { useEffect } from 'react';
import { useFormik } from 'formik';

interface InitialValuesType {
  id: any;
  name: string;
  description?: string;
  website?: string;
  parent: { label: string; value: string };
}

const CompanyForm = ({
  initialValues,
  handleCloseForm
}: {
  initialValues: InitialValuesType;
  handleCloseForm: () => void;
}) => {
  const { createCompanies } = useSelector((state: RootState) => state);
  const { listCompanies } = useSelector((state: RootState) => state);
  const { updateCompanies } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch({
      type: states.listCompanies.constants.request
    });
  }, []);

  const companyOptions =
    ((listCompanies.data as any[]) || []).map(
      (company: { id: string; name: string }) => ({
        value: company.id,
        label: company.name
      })
    ) || [];

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: companyValidationSchema,
    onSubmit: (values) => {
      if (values.id) {
        dispatch({
          type: states.updateCompanies.constants.request,
          params: { id: values?.id },
          payload: {
            ...values,
            parent: values.parent.value ? values.parent.value : null
          }
        });
      } else {
        const { id, ...restValues } = values;
        console.log(id);
        dispatch({
          type: states.createCompanies.constants.request,
          payload: {
            ...restValues,
            parent: values.parent.value ? values.parent.value : null
          }
        });
      }
    }
  });

  // Handle Select Change
  const handleSelectChange =
    (field: string) =>
    (selectedOption: { value: string; label: string } | null) => {
      formik.setFieldValue(field, selectedOption); // Update any field dynamically
    };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div key="name" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="name"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Name <span className="text-red-500">*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Enter subsidiary name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className={`border-gray-300 mt-1 block w-1/2 rounded-lg border px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand ${
              formik.errors.name && formik.touched.name
                ? 'border-red-500 focus:ring-red-500'
                : 'border-slate-200 focus:ring-indigo-500'
            }`}
          />
          {formik.errors.name && formik.touched.name && (
            <p className="text-xs text-red-500">{formik.errors.name}</p>
          )}
        </div>
        <div key="description" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="description"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter subsidiary description"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            className="border-gray-300 mt-1 block w-full rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
          />
        </div>
        <div key="website" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="website"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Website <span className="text-red-500">*</span>
          </label>
          <input
            id="website"
            name="website"
            type="text"
            placeholder="Enter website name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.website}
            className="border-gray-300 mt-1 block w-1/3 rounded-lg border border-slate-200 px-3 py-2 text-sm shadow-sm focus:border-brand focus:ring-brand focus:ring-indigo-500"
          />
        </div>
        <div key="parent" className="mb-5 rounded-[10px] bg-white p-5">
          <label
            htmlFor="website"
            className="text-gray-700 block text-sm font-medium"
          >
            {' '}
            Parent Company <span className="text-red-500">*</span>
          </label>
          <Select
            id="parent"
            name="parent"
            options={companyOptions}
            placeholder="Select the parent company"
            onChange={handleSelectChange('parent')}
            value={formik.values.parent} // Set the value from formik
            className="mt-1 block w-1/2 rounded-lg text-sm"
            classNamePrefix="react-select"
          />
        </div>
        <div className="mt-6 flex w-full justify-end">
          <div className="my-4 flex w-1/4 gap-3 text-sm font-semibold">
            <button
              onClick={handleCloseForm}
              className="w-full rounded-lg border border-brand-50 bg-none p-2 text-brand transition duration-300 ease-in-out hover:bg-slate-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-full justify-center rounded-lg border border-brand-200 bg-brand p-2 text-white transition duration-300 ease-in-out hover:bg-brand-400"
            >
              {createCompanies.loading || updateCompanies.loading ? (
                <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-current" />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CompanyForm;
