import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import DataTable from 'react-data-table-component';
import SearchBar from '../../components/SearchBar';
import AddNewsletterForm from './addNewsletterForm';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { states } from '../../redux/states/StateBuilder';
import { newsletterColumns } from './columns/newsletterColumn';
import DeleteModal from '../../components/ConfirmActionModal';
import { useResetState } from '../../utils/hooks/useResetState';

const NewsLetterPage: React.FC = () => {
  const listNewsletter = useSelector(
    (state: RootState) => state.listNewsletter
  );
  const deleteNewsletter = useSelector(
    (state: RootState) => state.deleteNewsletter
  );
  const createNewsletter = useSelector(
    (state: RootState) => state.createNewsletter
  );

  const updateNewsletter = useSelector(
    (state: RootState) => state.updateNewsletter
  );

  const dispatch = useDispatch();

  const [active, setActive] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [initialValue, setInitialValue] = useState({
    id: null,
    title: '',
    mainDescription: '',
    mainImage: '',
    departmentId: null,
    descriptionImage: '',
    descriptionTitle: '',
    sections: []
  });

  useEffect(() => {
    dispatch({ type: states.listNewsletter.constants.request });
  }, []);

  const handleSearchSubmit = (data: any) => {
    console.log(data);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setShowDeleteModal(false);
    setInitialValue({
      id: null,
      title: '',
      mainDescription: '',
      mainImage: '',
      departmentId: null,
      descriptionImage: '',
      descriptionTitle: '',
      sections: []
    });
  };

  const editAction = (data: any) => {
    setInitialValue({
      id: data.id,
      title: data.title,
      mainDescription: data.description,
      mainImage: data.imageUrl,
      departmentId: data.departmentId,
      descriptionImage: data.descriptionImage,
      descriptionTitle: data.descriptionTitle,
      sections: data.sections.map((section: any) => ({
        secTitle: section.title,
        description: section.description,
        image: section.imageUrl
      }))
    });

    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const Tabs = () => (
    <div className="m-2 w-[30%] rounded-lg">
      <ul
        className="relative flex list-none rounded-lg bg-[#F9FAFB] px-1.5 py-1.5"
        data-tabs="tabs"
      >
        {['Published', 'Drafts'].map((tab, index) => {
          const tabIndex = index + 1;
          const isActive = active === tabIndex;

          return (
            <div
              key={tabIndex}
              className={`mx-1 w-[90%] rounded-lg px-3 py-1 transition-all duration-300 ${
                isActive ? 'bg-white shadow-md' : ''
              }`}
            >
              <li className="z-30 flex-auto text-center">
                <button
                  className={`z-30 mb-0 flex w-full cursor-pointer items-center justify-center rounded-md border-0 bg-inherit px-0 py-2 text-sm transition-all ease-in-out ${
                    isActive ? 'font-semibold text-slate-800' : 'text-slate-600'
                  }`}
                  role="tab"
                  aria-selected={isActive}
                  onClick={() => setActive(tabIndex)}
                >
                  {tab}
                </button>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );

  const handleConfirmDelete = () => {
    dispatch({
      type: states.deleteNewsletter.constants.request,
      params: {
        id: deleteItem
      }
    });
  };

  const actions = [
    {
      icon: <FiEdit2 className="h-[20px] w-[20px] text-[#475467]" />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className="h-[20px] w-[20px] text-[#475467]" />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  useEffect(() => {
    if (
      createNewsletter.success ||
      deleteNewsletter.success ||
      updateNewsletter.success
    ) {
      handleCloseForm();
    }
  }, [
    createNewsletter?.success,
    deleteNewsletter.success,
    updateNewsletter.success
  ]);

  useResetState('createNewsletter', [createNewsletter.success as never]);
  useResetState('deleteNewsletter', [deleteNewsletter.success as never]);
  useResetState('updateNewsletter', [updateNewsletter.success as never]);

  return (
    <div>
      <DeleteModal
        isOpen={showDeleteModal}
        description="Are you sure you want to delete this item?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseForm}
        loading={deleteNewsletter.loading}
      />

      <PageHeader
        title="Newsletter"
        description="Create and manage news letters to keep your team updated."
      />
      {showForm && (
        <div className="mb-8 flex items-center space-x-2 text-brand-500">
          <button className="flex items-center" onClick={handleCloseForm}>
            <IoIosArrowRoundBack className="text-2xl" />
            <p className="text-normal font-semibold">Back</p>
          </button>
        </div>
      )}
      <div className="rounded-[10px] border-[1px] border-solid border-[#EAECF0] p-4">
        <div className="flex flex-row place-content-between">
          <div>
            <h1 className="px-2 py-5 text-lg font-semibold leading-9 text-[#101828]">
              NewsLetters
            </h1>
          </div>
          <div className="flex items-center justify-center px-2">
            <button
              className="rounded-lg bg-brand px-4 py-2 text-white"
              onClick={() => setShowForm(true)}
            >
              {' '}
              <div className="flex items-center">
                <span className="font-inter text-left text-base text-sm font-semibold">
                  + New newsletter
                </span>
              </div>
            </button>
          </div>
        </div>

        {/* Divider between title and table */}
        <div className="mb-3 border-t border-[#EAECF0]"></div>
        {!showForm && (
          <>
            <div className="flex w-full items-center justify-between">
              <Tabs />
              <SearchBar
                placeholder="Search..."
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className="m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]">
              <DataTable
                columns={newsletterColumns(actions)}
                data={(listNewsletter?.data as any[]) || []}
              />
            </div>
          </>
        )}

        {showForm && (
          <AddNewsletterForm
            initialValues={initialValue}
            handleCloseForm={handleCloseForm}
          />
        )}
      </div>
    </div>
  );
};

export default NewsLetterPage;
