import React from 'react';
import {
  LuBookOpen,
  LuBuilding2,
  LuCalendarPlus,
  LuPenLine
} from 'react-icons/lu';
import PageHeader from '../../components/PageHeader';
import { Link } from 'react-router-dom';
import EventCard from '../events/eventCard';
import { useAuth } from '../../providers/AuthProvider';

const HomePage: React.FC = () => {
  const { auth } = useAuth();
  return (
    <>
      <PageHeader
        title={`Welcome back, ${auth?.firstName}`}
        description="Explore recent activities and manage company content effortlessly."
      />

      {/* Quick Actions Section */}
      <div>
        <h2 className="mb-4 border-b border-brand-50 py-4 text-lg font-semibold text-brand-500">
          Quick actions
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <Link to={'/news-feed'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuPenLine className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-gray-700 text-sm font-medium">
                  Add news feed post
                </p>
                <p className="text-gray-500 text-xs">
                  Share updates with employees
                </p>
              </div>
            </button>
          </Link>
          {/* Create new event */}
          <Link to={'/events'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuCalendarPlus className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-gray-700 text-sm font-medium">
                  Create new event
                </p>
                <p className="text-gray-500 text-xs">
                  Plan and schedule company events
                </p>
              </div>
            </button>
          </Link>
          {/* Create new newsletter */}
          <Link to={'/newsletters'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuBookOpen className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-gray-700 text-sm font-medium">
                  Create new newsletter
                </p>
                <p className="text-xs text-brand-200">
                  Keep teams informed with a newsletter
                </p>
              </div>
            </button>
          </Link>

          {/* Add new subsidiary */}
          <Link to={'/subsidiaries'}>
            <button className="flex w-full items-center gap-3 rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border bg-brand-50">
                <LuBuilding2 className="h-6 w-6 text-brand-600" />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-gray-700 text-sm font-medium">
                  Add new subsidiary
                </p>
                <p className="text-gray-500 text-xs">
                  Add new subsidiary details
                </p>
              </div>
            </button>
          </Link>
        </div>
      </div>

      {/* Recent Feed News Section */}
      <div className="grid grid-cols-4 gap-4">
        <section className="col-span-3 mt-8">
          <h2 className="mb-4 text-lg font-semibold text-brand-500">
            Recent feed news
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <img
                src="https://picsum.photos/seed/picsum/200/300"
                alt="Independence Day"
                className="mb-2 h-64 w-full rounded-lg object-cover"
              />
              <h3 className="text-gray-700 font-medium">
                Happy Independence Day
              </h3>
              <p className="text-gray-500 text-sm">19 Jan 2022</p>
            </div>
            <div className="rounded-lg border border-brand-50 p-4 hover:shadow-md">
              <img
                src="https://picsum.photos/200/300.jpg"
                alt="Meeting"
                className="mb-2 h-64 w-full rounded-lg object-cover"
              />
              <h3 className="text-gray-700 font-medium">
                Team Collaboration Event
              </h3>
              <p className="text-gray-500 text-sm">19 Jan 2022</p>
            </div>
          </div>
        </section>
        <section className="col-span-1 mt-9 w-full gap-4">
          <h2 className="mb-4 text-brand-300">Upcoming Events</h2>
          {/* Use data from the server to remove duplicates */}
          <div className="grid max-h-[345px] gap-4 overflow-y-auto">
            {' '}
            <EventCard
              leftBarColor="blue"
              eventName="Umuganura"
              location="BK Arena"
              month={'August'}
              date="Tuesday, 1st July 2023"
              startTime="10:00 AM"
              endTime="2:00 PM"
            />
            <EventCard
              leftBarColor="yellow"
              eventName="Christmass day"
              location="BK Arena"
              date="Tuesday, 1st July 2023"
              month={'September'}
              startTime="10:00 AM"
              endTime="2:00 PM"
            />
            <EventCard
              leftBarColor="red"
              eventName="End oF Year"
              location="BK Arena"
              month={'October'}
              date="Tuesday, 1st July 2023"
              startTime="10:00 AM"
              endTime="2:00 PM"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
