import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { states } from '../../redux/states/StateBuilder';
import { RootState } from '../../redux/reducers';
import DataTable from 'react-data-table-component';
import { FiEdit2 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import PageHeader from '../../components/PageHeader';
import { teamColumns } from './columns/TeamColumn';
import AddNewMemberForm from './addNewMember';
import DeleteModal from '../../components/ConfirmActionModal';
import SearchBar from '../..//components/SearchBar';
import { IoIosArrowRoundBack } from 'react-icons/io';

export const customStyles = {
  header: {
    style: {
      backgroundColor: '#4a90e2',
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  },
  headCells: {
    style: {
      backgroundColor: '#F9FAFB',
      color: 'brand',
      fontWeight: '600',
      fontSize: '14px'
    }
  }
};

export default function TeamPage() {
  const { listUsers } = useSelector((state: RootState) => state);
  const { createUsers } = useSelector((state: RootState) => state);
  const { deleteUsers } = useSelector((state: RootState) => state);
  const { updateUsers } = useSelector((state: RootState) => state);

  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteItem, setDeleteItem] = useState(null);
  const [initialValues, setInitialValues] = useState({
    id: null,
    lastName: '',
    firstName: '',
    email: '',
    jobTitle: '',
    department: { label: '', value: '' },
    about: '',
    profileImage: null
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: states.listUsers.constants.request,
      params: { searchKey: null }
    });
  }, []);

  //SET USERDATA IN THE FORM
  const editAction = (data: any) => {
    setInitialValues({
      id: data?.id,
      lastName: data?.lastName,
      firstName: data?.firstName,
      email: data?.email,
      jobTitle: data?.jobTitle,
      department: {
        label: data?.department?.name,
        value: data?.department.id
      },
      about: data.about,
      profileImage: data?.profileImage ? data.profileImage : ''
    });
    setShowForm(true);
  };

  const deleteAction = (data: any) => {
    setDeleteItem(data.id);
    setShowDeleteModal(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setInitialValues({
      id: null,
      lastName: '',
      firstName: '',
      email: '',
      jobTitle: '',
      department: { label: '', value: '' },
      about: '',
      profileImage: null
    });
  };
  const actions = [
    {
      icon: <FiEdit2 className="h-[20px] w-[20px] text-[#475467]" />,
      handler: editAction,
      label: 'Edit'
    },
    {
      icon: <HiOutlineTrash className="h-[20px] w-[20px] text-[#475467]" />,
      handler: deleteAction,
      label: 'Delete'
    }
  ];

  const handleDelete = () => {
    dispatch({
      type: states.deleteUsers.constants.request,
      params: { id: deleteItem }
    });
  };
  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleSearchSubmit = (data: any) => {
    dispatch({
      type: states.listUsers.constants.request,
      params: { searchKey: data }
    });
  };

  useEffect(() => {
    if (createUsers.success || deleteUsers.success || updateUsers.success) {
      dispatch({
        type: states.createUsers.constants.reset
      });
      dispatch({
        type: states.deleteUsers.constants.reset
      });
      dispatch({
        type: states.updateUsers.constants.reset
      });
      setShowForm(false);
      setShowDeleteModal(false);
    }
  }, [createUsers?.success, deleteUsers.success, updateUsers.success]);

  return (
    <>
      <DeleteModal
        isOpen={showDeleteModal}
        description="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={handleCloseModal}
        loading={deleteUsers.loading}
      />
      <PageHeader
        title="Team"
        description="Add all the authorized team member information here"
      />
      {showForm && (
        <div className="mb-8 flex items-center space-x-2 text-brand-500">
          <button className="flex items-center" onClick={handleCloseForm}>
            <IoIosArrowRoundBack className="text-2xl" />
            <p className="text-normal font-semibold">Back</p>
          </button>
        </div>
      )}
      <div className="rounded-[10px] border-[1px] border-solid border-[#EAECF0] p-4">
        <div className="flex flex-row place-content-between">
          <div>
            <h1 className="px-2 py-5 text-lg font-semibold leading-9 text-[#101828]">
              Team members
            </h1>
          </div>

          {!showForm && (
            <div className="flex items-center justify-center px-2">
              <button
                className="rounded-lg bg-brand px-4 py-2 text-white"
                onClick={() => setShowForm(true)}
              >
                {' '}
                <div className="flex items-center">
                  <span className="font-inter text-left text-base text-sm font-semibold">
                    + New member
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>

        {/* Divider between title and table */}
        <div className="mb-3 border-t border-[#EAECF0]"></div>
        {!showForm && (
          <>
            <div className="flex w-full items-center justify-end">
              <SearchBar
                placeholder="Search..."
                onSubmit={handleSearchSubmit}
              />
            </div>
            <div className="m-2 rounded-[10px] border-[1px] border-solid border-[#EAECF0]">
              <DataTable
                columns={teamColumns(actions)}
                data={(listUsers?.data as any) ?? []}
                pagination={true}
                paginationPerPage={5}
                fixedHeader={true}
                customStyles={customStyles}
              />
            </div>
          </>
        )}
        {showForm && (
          <AddNewMemberForm
            initialValues={initialValues}
            handleCloseForm={handleCloseForm}
          />
        )}
      </div>
    </>
  );
}
